import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from 'mitt';
import sharedScripts from "@/dependencies/sharedScripts";

const eventBus = mitt();

// Global app status object
const globalStatus = {
    code: null,
    message: null,
    ok: true,
    warn: false,
    userMustDismiss: false,
    created: new Date().getTime(),
    createdTimeString: "",
    createdDateTimeString: "",
    clientSessonTimeout: false,
    forceLogout: false
};

// sessionStorage persistence
const storageKey = "adminApp";
const session = {
    get() {
        const storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        return storage;
    },
    add(key, value) {
        let storage = JSON.parse(sessionStorage.getItem(storageKey) || "{}");
        storage[key] = value;
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    save(storage) {
        sessionStorage.setItem(storageKey, JSON.stringify(storage));
    },
    deleteAll() {
        sessionStorage.removeItem(storageKey);
    }
}

let serverStatus = {
    serverBusy: false,
    busyText: ""
}

const authenticationCheck = async (vueInstance) => {
    // checkAndRefreshSession() returns either an updated state or a status object with errors
    let authenticationCheck = await sharedScripts.checkAndRefreshSession(vueInstance, vueInstance.adminState);
    if (authenticationCheck.hasOwnProperty("ok") && !authenticationCheck.ok) {
        eventBus.emit("updateStatus", authenticationCheck);
        if (authenticationCheck.forceLogout) eventBus.emit("forceLogout");
        return false;
    } else {
        eventBus.emit("updateAdminState", authenticationCheck);
        return authenticationCheck;
    }
}

// globally set dateOptions
const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true
}

// globally set timeOptions
const timeOptions = {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
};

// globally set dateOptions - Numbers Only
const shortDateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

// call to correct floating point imprecision
const roundFloat = (float) => {
    return Math.round((float + Number.EPSILON) * 100) / 100;
};

const generateUserIdColor = (userId) => {

    let colors = {
        background: 0,
        color: 0,
    };

    if (!userId) return colors;

    let guidArray = userId.match(/[^-]/g);
    let backgroundHex = [];
    for (let i = 0; i < 6; i++) {
        backgroundHex.push(guidArray[i]);
    }
    colors.background = backgroundHex.join("");
    // Calculate a contrasting font color
    colors.color = (Number(`0x1${colors.background}`) ^ 0xffffff).toString(16).substring(1);
    return colors;
}

const formatDateTimeISOString = (datetime, includeOffset = true) => {
    let timeZoneOffset = new Date().getTimezoneOffset() / 60;
    let signlessOffsetString = Math.abs(timeZoneOffset).toString();
    let addZero = signlessOffsetString.length === 1;
    let offsetIsPositive = Math.sign(timeZoneOffset) === 1;
    let offsetString = `${offsetIsPositive ? "-" : "+"}${addZero ? "0" : ""}${signlessOffsetString}00`;
    return `${datetime}${includeOffset ? offsetString : ''}`;
}

const payTableAU = (playDetails, prize) => {
    let numberOfLines = playDetails.reelsPlayDetails.numberOfLines;
    let baseBet = playDetails.reelsPlayDetails.baseBet;
    let denomAU = playDetails.reelsPlayDetails.denominationAU;

    let baseBetMultiple = prize.baseBetMultiple;
    let totalBetMultiple = prize.totalBetMultiple;
    let denomMultiple = prize.denomMultiple;

    // if numberOfLines is 0, we assume we're playing a Ways game.
    let lineMult = numberOfLines > 0 ? numberOfLines : 1;

    let baseBetPart = baseBet * baseBetMultiple;
    let totalBetPart = lineMult * baseBet * totalBetMultiple;
    return denomAU * (baseBetPart + totalBetPart + denomMultiple);
}

const accumulatedPayTableAU = (playDetails, endingState) => {
    let numberOfLines = playDetails.reelsPlayDetails.numberOfLines;
    let baseBet = playDetails.reelsPlayDetails.baseBet;
    let denomAU = playDetails.reelsPlayDetails.denominationAU;

    let accumulatedBaseBetMutliple = endingState.accumulatedBaseBetMutliple;
    let accumulatedTotalBetMultiple = endingState.accumulatedTotalBetMultiple;
    let accumulatedDenomMultiple = endingState.accumulatedDenomMultiple;

    // if numberOfLines is 0, we assume we're playing a Ways game.
    let lineMult = numberOfLines > 0 ? numberOfLines : 1;

    let baseBetPart = baseBet * accumulatedBaseBetMutliple;
    let totalBetPart = lineMult * baseBet * accumulatedTotalBetMultiple;
    return denomAU * (baseBetPart + totalBetPart + accumulatedDenomMultiple);
}


const registersArray = (registers) => {
    // Makes an array of registers from a registers object
    let regArray = [];
    for (let property in registers) {
        if (property === "wildBits") {
            regArray.push(`wild reels: ${registers[property]}`);
        } else {
            regArray.push(`${property}: ${registers[property]}`);
        };
    }
    return regArray;
}

// global default list limits
let limit = 20;
const limitOptions = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "250", value: 250 },
    { text: "500", value: 500 }
];

// International currency conversion
const localeString = process.env.VUE_APP_LOCALE_STRING;
const currency = process.env.VUE_APP_CURRENCY;
const divisor = 100;
const toLocaleCurrencyIntl = (fundsAU) => {
    if (isNaN(fundsAU)) fundsAU = 0;
    return new Intl.NumberFormat(localeString, { style: 'currency', currency: currency }).format(fundsAU / divisor);
}

const getDefaultRtps = (game) => {
    let denomAU = game.gameConfigOptions.denominationsAU;
    let rtps = game.gameConfigOptions.rtps;
    let rtpArray = new Array();
    let defaultRtp = null;

    // Since a disabled game has no RTPs, default to lowest RTP for all in game config options rtp array
    denomAU.forEach((denom, i) => {
        // need first rtp value as a default for restoring enabled status to a game
        if (!defaultRtp) defaultRtp = rtps[i];
        rtpArray.push(defaultRtp);
    });

    return rtpArray;
}


const stopBodyScroll = (openModal = false) => {
    // hack to fix iOS's stupid body scroll behavior with modal dialogs
    let view = document.getElementById('router-view');
    if (openModal) {
        view.classList.add('modal-opened');
    } else {
        view.classList.remove('modal-opened');
    }
}

const camelToTitleCase = (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const app = createApp(App).use(store).use(router);

app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.globalStatus = globalStatus;
app.config.globalProperties.session = session;
app.config.globalProperties.serverStatus = serverStatus;
app.config.globalProperties.authenticationCheck = authenticationCheck;
app.config.globalProperties.dateOptions = dateOptions;
app.config.globalProperties.timeOptions = timeOptions;
app.config.globalProperties.shortDateOptions = shortDateOptions;
app.config.globalProperties.camelToTitleCase = camelToTitleCase;
app.config.globalProperties.roundFloat = roundFloat;
app.config.globalProperties.generateUserIdColor = generateUserIdColor;
app.config.globalProperties.formatDateTimeISOString = formatDateTimeISOString;
app.config.globalProperties.payTableAU = payTableAU;
app.config.globalProperties.accumulatedPayTableAU = accumulatedPayTableAU;
app.config.globalProperties.registersArray = registersArray;
app.config.globalProperties.toLocaleCurrencyIntl = toLocaleCurrencyIntl;
app.config.globalProperties.getDefaultRtps = getDefaultRtps;
app.config.globalProperties.stopBodyScroll = stopBodyScroll;
app.config.globalProperties.limitOptions = limitOptions;
app.config.globalProperties.limit = limit;
app.config.globalProperties.rabbitsfootHostUrl = process.env.VUE_APP_RABBITSFOOT_HOST_URL;
app.config.globalProperties.devMode = process.env.VUE_APP_DEV_MODE === "true";
app.config.globalProperties.localeString = localeString;
app.config.globalProperties.currency = currency;
app.config.globalProperties.operatorAppsVersion = process.env.OPERATOR_APPS_VERSION;
app.config.globalProperties.phoneNumberLength = process.env.VUE_APP_PHONE_LENGTH;
app.config.globalProperties.casinoGroupName = process.env.VUE_APP_CASINO_GROUP_NAME;

// Global bool to check if server is busy handling a refresh token call.
// Prevents multiple components trying to refresh access token simultaneously.
// This can happen on UserManager due to the "keep-alive" tabs while changing selected user.
app.config.globalProperties.refreshTokenAwait = false;

// Set app.config.performance to true to enable component init, compile, render and patch performance tracing
// in the browser devtools performance timeline panel.
// Only active in development mode and in browsers that support the performance mark API.
// Enables Vue-specific performance markers in Chrome DevTools' performance timeline.
// https://vuejs.org/api/application.html#app-config-performance
app.config.performance = true;

app.mount('#app');
