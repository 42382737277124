function isValidOpenValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= 0 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

function isValidTransferValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= -1000000000 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

async function getCasinoList(context, casinoId = null) {
    try {
        let requestUrl = new URL(`/api/v1/site/${casinoId || ""}`, context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: 'GET',
            headers: headerObj,
        });
        const response = await fetch(request);
        let dataObj = await response.json();

        return dataObj;
    } catch (e) {
        console.error(e);
    }
}

async function getCasinosIncludingDeleted(context, casinoId = null) {
    try {
        let requestUrl = new URL("/api/v1/site/listincludingdeleted", context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Authorization", `Bearer ${context.adminState.accessToken}`);
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: 'GET',
            headers: headerObj,
        });
        const response = await fetch(request);

        if (response?.ok === false) {
            return response;
        }

        let dataObj = await response.json();

        return dataObj;
    } catch (e) {
        console.error(e);
    }
}

function checkFetchErrors(fetchResponse) {
    let status = {
        code: fetchResponse?.status?.code ? fetchResponse.status.code : fetchResponse.status,
        message: "",
        ok: true,
        warn: false,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    if ((!fetchResponse.length > 0 && !fetchResponse.ok) || fetchResponse.status >= 400) {
        status.ok = false;
        switch (fetchResponse.status) {
            case 400:
                status.message = "Bad Request";
                break;
            case 401:
                status.message = "Session is expired. Please log in again.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 403:
                status.message = "You don't have permissions to do that at this Casino";
                status.userMustDismiss = true;
                break;
            case 404:
                status.message = "Not Found";
                status.userMustDismiss = true;
                break;
            case 409:
                status.message = "Your account has been accessed from another device or tab. Please log in again.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 423:
                // 423 is the code we are using for locked user account
                status.message = "Your account has been locked. Please contact support.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 500:
                status.message = "Internal Server Error. Please try again in a few seconds.";
                break;
            case 503:
                status.message = "Service Unavailable";
                break;
            case undefined:
                // For cases when server returns an empty array or any other undefined status code
                status.code = Array.isArray(fetchResponse) ? 200 : null;
                status.message = Array.isArray(fetchResponse) && fetchResponse?.length === 0 ? "Server returned an empty array" : "Status code undefined";
                status.ok = Array.isArray(fetchResponse) ? true : false;
                status.isArray = Array.isArray(fetchResponse) ? true : false;
                break;
            default:
                status.message = fetchResponse.status;
                status.userMustDismiss = true;
        }
        if (!status.ok) console.error(status);
    }
    return status;
}

function checkSuccessErrors(serverStatus) {
    // errors that are a server 200 but still are an error state for this application.
    // setting status.code to null even though it was 200 so that 200 doesn't show in the status block of the site header
    let status = {
        code: null,
        message: null,
        ok: true,
        warn: false,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    let statusList = [
        "Success",
        "InvalidUser",
        "MiscellaneousError",
        "PasswordTooShort",
        "PasswordTooFewCharacterClasses",
        "PasswordNotAllowed",
        "PasswordIncorrect",
        "DisplayNameTooShort",
        "PhoneNumberInvalidCharacters",
        "PhoneNumberAlreadyInUse",
        "UnableToSendCode",
        "InvalidCode",
        "FailedToSend",
        "TransactionNotFound",
        "TransactionAlreadyResolved",
        "MruNotFound",
        "UserAccountIsLocked"
    ];

    status.ok = !statusList.includes(serverStatus) ? true : false;

    switch (serverStatus) {
        case statusList[0]:
            // "Success"
            status.code = 200;
            status.ok = true;
            status.message = "Success";
            break;
        case statusList[1]:
            // "InvalidUser"
            status.message = "Invalid User.";
            break;
        case statusList[2]:
            // "MiscellaneousError"
            status.message = "An unkown error has occurred. Please try again.";
            break;
        case statusList[3]:
            // "PasswordTooShort"
            status.message = "Password Too Short. Must be at least 3 characters.";
            break;
        case statusList[4]:
            // "PasswordTooFewCharacterClasses"
            status.message = "Password Too Few Character Classes";
            break;
        case statusList[5]:
            // "PasswordNotAllowed"
            status.message = "Password Not Allowed";
            break;
        case statusList[6]:
            // "PasswordIncorrect"
            status.message = "Current Password is incorrect.";
            break;
        case statusList[7]:
            // "DisplayNameTooShort"
            status.message = "Display Name too short";
            break;
        case statusList[8]:
            // "PhoneNumberInvalidCharacters"
            status.message = "Phone Number Invalid Characters";
            break;
        case statusList[9]:
            // "PhoneNumberAlreadyInUse"
            status.message = "Phone Number already in use";
            break;
        case statusList[10]:
            // "UnableToSendCode"
            status.message = "Unable To Send Code";
            break;
        case statusList[11]:
            // "InvalidCode"
            status.message = "Invalid Confirmation Code";
            break;
        case statusList[12]:
            // "FailedToSend"
            status.message = "The server is unable to send an SMS to the user's phone number";
            break;
        case statusList[13]:
            // "TransactionNotFound"
            status.message = "Transaction Not Found";
            break;
        case statusList[14]:
            // "TransactionAlreadyResolved"
            status.warn = true;
            status.message = "Transaction Already Resolved";
            break;
        case statusList[15]:
            // "MruNotFound"
            status.message = "Mru Not Found";
            break;
        case statusList[16]:
            // "UserAccountIsLocked"
            status.ok = false;
            status.message = "User Account Is Locked";
            break;
        default:
            status.message = "Unknown Status";
            status.ok = false;
    };
    return status;
}

async function checkAndRefreshSession(vueInstance) {
    let status = Object.assign({}, vueInstance.globalStatus);
    let appState = vueInstance.adminState;
    let responseJSON = {};
    let min = 60000; // for testing purposes: 1 min
    let hour = 3600000;

    // Comparing old/new access tokens with 30 seconds wiggle room for potential server latency.
    let tokenExpired = appState.accessToken && new Date().getTime() > new Date(appState.accessTokenExpiration).getTime() - 30000;

    // Check to see if if server is still busy handling a refresh token call and
    // reject any additional refresh calls because the tokens and refresh tokens can get out of sync.
    // Non-standard Response Code 999 so that we can filter this error out for production builds, as this error message
    // is not too useful and possibly confusing for the non-dev user. Dev should still see this error notification.
    if (vueInstance.refreshTokenAwait) {
        status.code = 999;
        status.message = "Refresh Access Token has already been called.";
        status.ok = false;
        if (vueInstance.devMode) console.warn(status);
        return status;
    }

    // set two hour client session time out: 2 * hour
    if (appState.accessToken && new Date().getTime() > appState.loggedOnTimeCode + 2 * hour) {
        status.message = "User session has timed out"
        status.ok = false;
        status.clientSessonTimeout = true;
        status.forceLogout = true;
        return status;
    }

    if (tokenExpired) {
        let body = {
            oldAccessToken: appState.accessToken,
            refreshToken: appState.refreshToken,
        };

        let requestUrl = new URL("/api/v1/authentication/refresh", vueInstance.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: "POST",
            body: JSON.stringify(body),
            headers: headerObj,
        });

        try {
            // Bool to indicate the server is starting to handle a refresh token call
            vueInstance.refreshTokenAwait = true;

            const response = await fetch(request);

            let fetchStatus = checkFetchErrors(response);

            if (!fetchStatus.ok || response === "Bad OldAccessToken") {
                console.error(response);
                vueInstance.refreshTokenAwait = false;
                return fetchStatus || response;
            }

            responseJSON = await response.json();

            appState.accessTokenExpiration = responseJSON.accessTokenExpiration;
            appState.accessToken = responseJSON.accessToken;
            appState.refreshToken = responseJSON.refreshToken;

            // Server success handling the refresh token call
            vueInstance.refreshTokenAwait = false;

            return appState;
        } catch (e) {
            // Even though something went wrong, server is done handling this call so we set this to false
            vueInstance.refreshTokenAwait = false;
            status.message = `checkAndRefreshSession returned: ${e}`;
            status.ok = false;
            return status;
        }
    } else if (appState.accessToken) {
        status.message = "Session from cache";
    }
    return status;
}

async function saveSimpleConfig(context) {
    let requestUrl = new URL("/api/v1/admin/game/simpleconfig", context.rabbitsfootHostUrl);
    let headerObj = new Headers();
    headerObj.append("Authorization", `Bearer ${context.adminState.accessToken}`);
    headerObj.append("Content-Type", "application/json; charset=utf-8");
    let request = new Request(requestUrl.toString(), {
        method: "POST",
        body: JSON.stringify(context.simpleConfigSaveData),
        headers: headerObj,
    });

    try {
        let response = await fetch(request);
        let fetchStatus = checkFetchErrors(response);

        return fetchStatus;
    } catch (e) {
        console.error(e);
    }
}

export default {
    getCasinoList,
    checkFetchErrors,
    isValidOpenValue,
    isValidTransferValue,
    checkSuccessErrors,
    checkAndRefreshSession,
    saveSimpleConfig,
    getCasinosIncludingDeleted
};