<template>
	<Header :casinoName="adminState?.casinoName" :isMobile="isMobile" />
	<div id="nav-container" :class="isMobile ? 'isMobile' : ''">
		<button id="navbar-button" v-if="isMobile" @click="toggleNavBar()" class="nav-button-container btn">
			<div></div>
			<div></div>
			<div></div>
		</button>
		<MainNavbar :class="navBarClass" :adminState="adminState" :isMobile="isMobile" />
	</div>
	<Login :adminState="adminState" :casinoList="casinoList" :isMobile="isMobile" :serverBusy="serverBusy" :busyText="busyText" />
	<router-view
		id="router-view"
		:key="$route.fullPath"
		:adminState="adminState"
		:casinoList="casinoList"
		:deletedCasinos="deletedCasinos"
		:disabledCasinos="disabledCasinos"
		:casinosIncludingDeleted="casinosIncludingDeleted"
		:isMobile="isMobile"
		:serverVersion="serverVersion"
		:inGameCurrencyTool="inGameCurrencyTool"
		:systemCurrencyTool="systemCurrencyTool"
		:systemSettings="systemSettings"
	/>
	<Footer :serverVersion="serverVersion" :isMobile="isMobile" />
</template>

<script>
// @ is an alias to /src
import store from "@/store/index";
import router from "@/router";
import Login from "@/components/Login.vue";
import sharedScripts from "@/dependencies/sharedScripts";
import MainNavbar from "@/components/MainNavbar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CurrencyTool from "@/dependencies/currencyTool";

export default {
	name: "App",
	components: {
		Login,
		MainNavbar,
		Header,
		Footer,
	},
	data() {
		return {
			windowWidth: window.innerWidth,
			isMobile: this.windowWidth < 768,
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			serverVersion: {},
			messageResetTimer: null,
			adminState: this.session.get("adminApp") || {},
			casinoList: [],
			deletedCasinos: [],
			disabledCasinos: [],
			mainNavbarOpen: false,
			navBarClass: "",
			casinosIncludingDeleted: [],
			inGameCurrencyTool: null,
			systemCurrencyTool: null,
			systemSettings: {},
		};
	},
	watch: {
		windowWidth() {
			this.onResize();
		},
	},
	async created() {
		await this.checkServerVersion();
		await this.getSystemSettings();
		await this.getCasinos();

		let thisInstance = this;
		this.eventBus.on("toggleNavBar", (forceClose = false) => {
			if (forceClose) {
				thisInstance.mainNavbarOpen = false;
				this.navBarClass = "";
			} else {
				thisInstance.toggleNavBar();
			}
		});
		this.eventBus.on("updateAdminState", (payload) => {
			thisInstance.updateAdminState(payload);
		});
		this.eventBus.on("refreshClientTimeout", () => {
			let newState = thisInstance.adminState;
			newState.loggedOnTimeCode = new Date().getTime();
			thisInstance.updateAdminState(newState);
		});
		this.eventBus.on("updateCasinoList", () => {
			this.getCasinos();
			thisInstance.updateThisCasino();
		});
		this.eventBus.on("updateCasinosIncludingDeleted", () => {
			thisInstance.getCasinosIncludingDeleted();
			thisInstance.updateThisCasino();
		});
		this.eventBus.on("resetCasinosIncludingDeleted", () => {
			this.casinosIncludingDeleted = [];
			this.deletedCasinos = [];
			this.disabledCasinos = [];
		});
		this.eventBus.on("updateErrorList", (payload) => {
			let newErrorList = JSON.parse(sessionStorage.getItem("errorList")) || store.state.errorList;
			if (newErrorList.length > 0 && newErrorList.length > 99) newErrorList.pop();
			newErrorList.push(payload);
			store.dispatch("setErrorList", newErrorList);
			sessionStorage.setItem("errorList", JSON.stringify(newErrorList));
		});
		this.eventBus.on("resetCurrencyToolConstructor", () => {
			// This tool passes currencyInfo to the constructor for
			// in-game currency.
			this.inGameCurrencyTool = new CurrencyTool(this.adminState.currencyInfo, this.localeString);
			// This tool passes systemSettings.cashOutCurrency to the constructor for
			// real-world currency formatting info.
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
		});
	},
	mounted() {
		router.push("/");
		let thisInstance = this;
		this.$nextTick(() => {
			window.addEventListener("resize", thisInstance.onResize);
			thisInstance.onResize();
		});
		window.onpopstate = (e) => {
			// Force browser back button to home
			// Web history state has problems when we deploy in a subfolder
			// This is to fix the back button causing empty page to be displayed
			router.push("/");
		};
		this.eventBus.emit("resetCurrencyToolConstructor");

		// Collapse the navbar if user clicks routerview so nav is a little more user friendly
		// Only affect is on mobile navbar
		let body = document.getElementsByTagName("body")[0];
		body.addEventListener("click", (e) => {
			let routerView = document.getElementById("router-view");
			if (thisInstance.mainNavbarOpen == true && routerView?.contains(e.target)) this.toggleNavBar();
		});
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
			this.isMobile = this.windowWidth < 768 || false;
			this.mainNavbarOpen = false;
			this.navBarClass = "";
		},
		toggleNavBar() {
			this.mainNavbarOpen = this.mainNavbarOpen ? false : true;
			this.navBarClass = this.mainNavbarOpen ? "open" : "";
		},
		updateAdminState(changedState) {
			let mergedAdminState = {};
			if (Object.keys(changedState).length !== 0) {
				mergedAdminState = {
					...this.adminState,
					...changedState,
				};
			}
			this.inGameCurrencyTool = new CurrencyTool(this.adminState.currencyInfo, this.localeString);
			this.systemCurrencyTool = new CurrencyTool(this.systemSettings.cashOutCurrency, this.localeString);
			this.adminState = mergedAdminState;
			this.adminState.loggedOnTimeCode = new Date().getTime();
			this.session.save(mergedAdminState);
			store.dispatch("setAdminState", mergedAdminState);
		},
		async checkServerVersion() {
			let requestUrl = new URL("/api/v1/version", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.serverVersion = dataJson;
			} catch (e) {
				console.error(e);
				console.error("Server not responding to version request");
			}
		},
		async getSystemSettings() {
			let requestUrl = new URL("/api/v1/system/settings", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				this.systemSettings = dataJson;
			} catch (e) {
				console.error(e);
				console.error("Server not responding to System Settings request");
			}
		},
		async getCasinos() {
			this.serverBusy = true;
			this.busyText = "Loading Casino List";

			try {
				// Get Casino List and save to state management
				let response = await sharedScripts.getCasinoList(this);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				this.casinoList = await response.sort((a, b) => a.name.localeCompare(b.name));

				this.status = fetchStatus;

				if (this.status.isArray) console.warn(this.status.message);

				store.dispatch("setCasinoList", this.casinoList || []);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
			}
		},
		async getCasinosIncludingDeleted() {
			// Check if server is not already refreshing access token. Return if true.
			if (this.refreshTokenAwait) return false;
			if (this.adminState.permissionsSuspended) return false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			try {
				this.serverBusy = true;
				this.busyText = "Getting Casinos, Including Deleted";
				// Get Casino List including deleted casinos and save to session storage
				let response = await sharedScripts.getCasinosIncludingDeleted(this);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.status = fetchStatus;
					this.eventBus.emit("updateStatus", this.status);
					if (this.status?.code === 409) this.eventBus.emit("forceLogout");
					return;
				}

				this.casinosIncludingDeleted = await response.sort((a, b) => a.name.localeCompare(b.name));

				this.getDisabledCasinos();
				this.getDeletedCasinos();
				// this.eventBus.emit("casinosIncludingDeletedSuccess");
				store.dispatch("setCasinosIncludingDeleted", this.casinosIncludingDeleted || []);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List including deleted");
			}
		},
		getDisabledCasinos() {
			this.disabledCasinos = [];
			this.casinosIncludingDeleted.forEach((casino) => {
				if (casino.isEnabled !== true) this.disabledCasinos.push(casino);
			});
		},
		getDeletedCasinos() {
			this.deletedCasinos = [];
			this.casinosIncludingDeleted.forEach((casino) => {
				if (casino.isDeleted === true) this.deletedCasinos.push(casino);
			});
		},
		async updateThisCasino() {
			this.serverBusy = true;
			this.busyText = "Update This Casino";

			try {
				let response = await sharedScripts.getCasinoList(this, this.adminState.casinoId);
				let newCasino = await response;

				let mergedCasinoList = [];

				this.casinoList.forEach((casino) => {
					mergedCasinoList.push(casino.id === newCasino.id ? newCasino : casino);
				});

				this.casinoList = mergedCasinoList.sort((a, b) => a.name.localeCompare(b.name));
				store.dispatch("setCasinoList", mergedCasinoList || []);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				console.error("Failed getting Casino List");
			}
		},
	},
};
</script>

<style>
body,
textarea {
	font-family: "Roboto", sans-serif;
}

body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden auto;
	color: #fff;
	background-color: #434250;
}

* {
	overscroll-behavior: none;
}

.gradient-background {
	background: linear-gradient(346deg, #356d1e, #22bf4d, #a56dd4, #2386f7, #a94809, #cc66e7);
	background-size: 300% 300%;
	animation: gradient-animation 12s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* hack to fix iOS's stupid body scroll behavior with modal dialogs */
#router-view.modal-opened {
	/* height: 100%; */
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
}

h1,
h2,
h3,
.loading-message {
	text-align: center;
	display: block;
}

h1 {
	font-size: 1.5em;
}

.loading-message {
	margin: 15px;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

/* #nav-container {
	position: relative;
} */

#nav-container {
	position: fixed;
	top: 98px;
	width: 100%;
}

#nav-container.isMobile {
	z-index: 5;
}

.nav-button-container {
	position: fixed !important;
	top: 15px;
	left: 15px !important;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	padding: 0;
	margin: 0;
	z-index: 1;
	border-radius: 100% !important;
	overflow: hidden;
}

.nav-button-container div {
	margin: 3px;
	align-self: center;
	width: 30px;
	height: 4px;
	background-color: #fff;
	border-radius: 5px;
}

#router-view {
	/* position: relative; */
	position: fixed;
	top: 80px;
	width: 100vw;
	margin: auto;
	padding: 15px 0 0;
	overflow: hidden auto;
	height: calc(100vh - 160px);
}

#app {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	user-select: none;
	cursor: pointer;
}

legend {
	margin: auto;
	padding: 0 15px;
	font-size: 1.75em;
	text-align: center;
}

input,
label,
select,
textarea,
button {
	margin: 5px 10px;
}

input,
select,
textarea,
input:focus,
select:focus,
textarea:focus {
	font-size: 16px;
}

table {
	border-collapse: collapse;
}

button {
	width: auto;
}

input {
	color: #000;
	font-weight: 700;
}

th,
.header-row {
	font-weight: 700;
	text-transform: uppercase;
}

.sticky {
	position: sticky;
	top: -1px;
	background-color: #000;
	color: #fff;
	text-align: center;
	z-index: 1;
}

#paging {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 100%;
	justify-content: center;
	margin: auto;
	user-select: none;
}

.loading-icon {
	display: block;
	content: "";
	position: relative;
	top: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 1px solid;
	border-radius: 100%;
	border-color: red white blue black;
	animation: loader 0.5s linear infinite;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.outline-white {
	text-shadow: -1px -1px 0px #fff, 1px -1px 0px #fff, -1px 1px 0px #fff, 1px 1px 0px #fff;
}

.outline-black {
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

.btn {
	position: relative;
	padding: 5px 10px;
	right: 15px;
	left: 0;
	background-color: #5b88c0;
	color: #fff;
	border-radius: 7px;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 30%), inset -1px -1px 5px rgb(0 0 0 / 30%);
	border: none;
	transition: box-shadow 0.2 ease-in-out, transform 0.2s ease-in-out;
	cursor: pointer;
}

.btn:hover {
	transform: scale(1.05);
	box-shadow: 2px 2px 8px rgb(0 0 0 / 80%), inset -1px -1px 5px rgb(0 0 0 / 10%);
}

.reg-au {
	color: #0000bf;
}

.promo-au {
	color: #812f00;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
	transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
	transform: translateY(100vh);
}

/* .slidedown-enter-active,
.slidedown-leave-active {
	transition: height 0.3s ease;
}

.slidedown-enter-from,
.slidedown-leave-to {
	height: 0;
} */

.operator-id,
.cashier-id {
	text-align: left;
}

.operator-id span,
.cashier-id span {
	padding: 5px;
	font-weight: bold;
	border-radius: 100%;
	filter: contrast(1.5);
}

hr.spin-hr {
	background-color: #000;
	margin: 4em 30px;
}

hr.heading-divider {
	margin-top: 150px;
}

code {
	padding: 0.1em 0.2em;
	margin: 0;
	font-size: 0.8em !important;
	background-color: rgb(133 133 133 / 40%);
	border-radius: 6px;
	font-size: 1em;
	font-family: "Courier New", monospace;
	overflow-wrap: anywhere;
	white-space: pre-wrap;
}

.psuedo-anchor {
	color: rgb(80, 80, 255);
	cursor: pointer;
	text-decoration: underline;
}

.psuedo-anchor:visited {
	color: rgb(85, 26, 139);
}

.psuedo-anchor:active {
	color: rgb(255, 0, 0);
}

.read-only {
	text-align: center;
	font-weight: bold;
}

.red-bg {
	background-color: #f00;
}

.red-text,
.read-only {
	color: #f00;
}

.white-text {
	color: #fff;
}

.yellow-bg {
	background-color: #ff0;
	color: #000;
}

.yellow-text {
	color: #ff0;
}

.cash-display {
	text-align: right;
}

.psuedo-anchor:focus-visible {
	outline-color: rgb(16, 16, 16);
	outline-offset: 1px;
	outline-width: 1px;
}

dialog {
	margin: auto 15px;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
}

[disabled] {
	cursor: wait;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#router-view > div {
		margin-bottom: 150px;
	}

	#play-details,
	.user-list-container {
		padding-bottom: 60px;
	}
}

@media (min-width: 768px) {
	#router-view {
		top: 98px;
		width: 80%;
		margin-left: 20%;
		height: calc(100vh - 172px);
	}

	h1 {
		font-size: 2em;
	}

	#router-view {
		width: 80%;
		margin-left: 20%;
	}

	.operator-id,
	.cashier-id {
		text-align: center;
	}

	dialog {
		margin: auto;
	}
}
</style>
