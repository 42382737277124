<template>
	<header>
		<h1 v-if="casinoName"><span v-if="!isMobile">Admin Panel for </span>{{ casinoName }}</h1>
		<h1 v-else>Admin Panel</h1>
		<div class="clock" v-if="!isMobile">
			<span class="time">{{ time }}</span>
			<span>{{ dayLong }}</span>
			<span>{{ dateLong }}</span>
		</div>
		<div v-if="devMode && casinoName" class="access-status">
			Access Token:
			<span :class="accessTokenState ? 'active' : 'expired'" :title="accessTokenState ? 'Active' : 'Expired'"
				>{{ accessTokenState ? "Active" : "Expired" }} |
				<span :title="accessTokenState ? 'Seconds until access token expires' : 'Seconds since access token expired'">{{ expires }} sec</span>
				<span
					v-if="expires > 120"
					:title="accessTokenState ? 'Approx minutes until access token expires' : 'Approx minutes since access token expired'"
				>
					({{ accessTokenState ? "&lt;" : "&gt;" }}{{ accessTokenState ? Math.ceil(expires / 60) : Math.ceil(expires / 60) - 1 }} min)</span
				>
			</span>
		</div>
	</header>
	<div class="notifications" v-if="notificationList.length > 0">
		<button class="dismiss-notify btn" title="Dismiss all notifications" @click="dismissNotifications()"></button>
		<table>
			<tr>
				<th>Time</th>
				<th>Notification</th>
			</tr>
			<tr v-for="(item, index) in notificationList" :key="index" :class="setNotificationClass(item)">
				<td>{{ item.createdTimeString }}</td>
				<td v-if="item.message">{{ item.message }}</td>
				<td v-else>{{ item.code }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "Header",
	inheritAttrs: false,
	props: {
		casinoName: String,
		isMobile: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			date: new Date(),
			dateLong: "",
			dayLong: "",
			time: "",
			notificationList: [],
			accessTokenState: false,
			expires: 0,
		};
	},
	created() {
		// initial date/time setup for header clock
		this.updateDateTime();
		let thisInstance = this;
		this.eventBus.on("updateStatus", (payload) => {
			let notify = Object.assign({}, payload);
			let date = new Date();
			notify.created = date.getTime();
			notify.createdTimeString = date.toLocaleTimeString(this.localeString);
			notify.createdDateTimeString = date.toLocaleTimeString([], thisInstance.dateOptions);
			if (notify.message !== "Session from cache") {
				if (thisInstance.devMode) this.eventBus.emit("updateErrorList", notify);
				// Only push notifications for response code 999 and 200 in devMode. User doesn't need to see those.
				if (!thisInstance.devMode && (payload.code === 999 || payload.code === 200)) return;
				thisInstance.notificationList.push(notify);
			}
			thisInstance.eventBus.emit("refreshClientTimeout");
		});
	},
	mounted() {
		let thisInstance = this;
		setInterval(() => {
			thisInstance.updateDateTime();
			thisInstance.updateTokenState();
			thisInstance.notificationCleanUp();
		}, 1000);
	},
	methods: {
		setNotificationClass(item) {
			if (!item.ok) return "error";
			if (item.warn) return "warn";
			if (item.ok) return "ok";
		},
		dismissNotifications() {
			this.notificationList = [];
		},
		notificationCleanUp() {
			// notification box cleanup
			if (this.notificationList.length > 0) {
				let timeOffset = this.date.getTime() - 15000;
				this.notificationList = this.notificationList.filter((notification) => {
					return notification.created > timeOffset;
				});
			}
		},
		updateTokenState() {
			// Access token state indicator. Only running while in dev mode.
			if (this.devMode && this.casinoName) {
				let adminApp = JSON.parse(sessionStorage.getItem("adminApp"));
				let accessTokenExpiration = adminApp.accessTokenExpiration;
				let tokenExpirationMs = new Date(accessTokenExpiration).getTime();
				let expires = Math.abs(tokenExpirationMs - new Date().getTime());
				this.accessTokenState = new Date().getTime() <= tokenExpirationMs;
				this.expires = Math.ceil(expires / 1000);
			}
		},
		updateDateTime() {
			this.date = new Date();
			this.dateLong = this.date.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
			this.dayLong = this.date.toLocaleDateString(undefined, { weekday: "long" });
			this.time = this.date.toLocaleTimeString(undefined, this.timeOptions);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	text-align: center;
}

header {
	position: fixed;
	width: 100%;
	background-color: #32373f;
	color: #fff;
	z-index: 5;
}

header h1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	height: 80px;
	display: grid;
	align-content: center;
	margin: 0 80px;
	font-size: 1.375em;
}

.status-message {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}

.status-message {
	position: absolute;
	top: 15px;
	left: 15px;
	padding: 10px 15px;
	border-radius: 6px;
	font-weight: 700;
	transition: background-color 0.3s ease, color 0.3s ease;
	z-index: 500;
}

.status-message.ok,
.notifications tr.ok {
	background-color: #0f0;
	color: #000;
}

.status-message.error,
.notifications tr.error {
	background-color: #f00;
	color: #fff;
}

.status-message.warn,
.notifications tr.warn {
	background-color: #ff0;
	color: #000;
	font-weight: bold;
}

.status-message span {
	display: block;
}

.site-details-btn {
	position: fixed;
	right: 1em;
	bottom: 1em;
	z-index: 100;
}

.clock {
	display: flex;
	flex-flow: column nowrap;
	position: absolute;
	top: 15px;
	user-select: none;
}

.time {
	font-size: 1.5em;
}

.notifications {
	position: fixed;
	margin: 15px;
	padding: 15px;
	top: unset;
	left: 0;
	bottom: 0;
	background: rgb(0 0 0 / 60%);
	backdrop-filter: blur(5px);
	z-index: 10000;
	border-radius: 10px;
}

.notifications tr:not(:first-child) {
	border: 1px solid #000;
}

.notifications table {
	display: block;
	height: hidden auto;
	max-height: calc(100vh - 10em);
	overflow: auto;
}

.notifications td {
	padding: 10px;
}

.dismiss-notify {
	position: absolute;
	right: -30px;
	top: -25px;
	left: unset;
	font-weight: bold;
	font-size: 2em;
	width: 40px;
	height: 40px;
	min-width: unset;
	padding: 5px 10px;
	user-select: none;
	border: 1px #fd00ff solid;
	border-radius: 100%;
	display: flex;
	line-height: 0;
	align-items: center;
	justify-content: center;
}

.dismiss-notify::after {
	content: "\00D7";
	position: relative;
	top: 1px;
}

.access-status {
	position: fixed;
	text-align: center;
	width: 100%;
	top: 8px;
}

.expired,
.active {
	font-weight: bold;
	cursor: help;
}

.expired {
	color: #f00;
}

.active {
	color: #008000;
}

@media (min-width: 768px) {
	header {
		padding: 30px;
	}

	header h1 {
		margin: 0 3em 0 5em;
		height: auto;
		display: block;
		font-size: 2em;
	}

	.notifications {
		top: 0;
		bottom: unset;
	}
}
</style>
