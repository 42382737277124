import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Welcome to Admin App Home'
    }
  },
  {
    path: "/casinoAdministration",
    name: "CasinoAdministration",
    component: () =>
      import(/* webpackChunkName: "casinoAdministration" */ "@/views/CasinoAdministration.vue"),
    meta: {
      title: 'Casino Administration'
    }
  },
  {
    path: "/userManager",
    name: "UserManager",
    component: () =>
      import(/* webpackChunkName: "userManager" */ "@/views/UserManager.vue"),
    meta: {
      title: 'User Manager'
    }
  },
  {
    path: "/mruAdministration",
    name: "MruAdministration",
    component: () =>
      import(/* webpackChunkName: "mruAdministration" */ "@/views/MruAdministration.vue"),
    meta: {
      title: 'MRU Administration'
    }
  },
  {
    path: "/createOrDeleteCasino",
    name: "CreateOrDeleteCasino",
    component: () =>
      import(/* webpackChunkName: "createOrDeleteCasino" */ "@/views/CreateOrDeleteCasino.vue"),
    meta: {
      title: 'Create Or Delete Casino'
    }
  },
  {
    path: "/buyInsManager",
    name: "BuyInsManager",
    component: () =>
      import(/* webpackChunkName: "buyInsManager" */ "@/views/BuyInsManager.vue"),
    meta: {
      title: 'Buy-Ins Manager'
    }
  },
  {
    path: "/progressiveLimits",
    name: "ProgressiveLimits",
    component: () =>
      import(/* webpackChunkName: "progressiveLimits" */ "@/views/ProgressiveLimits.vue"),
    meta: {
      title: 'Progressive Settings'
    }
  },
  {
    path: "/documentManager",
    name: "DocumentManager",
    component: () =>
      import(/* webpackChunkName: "documentManager" */ "@/views/DocumentManager.vue"),
    meta: {
      title: 'Document Manager'
    }
  },
  {
    path: "/systemInfo",
    name: "SystemInfo",
    component: () =>
      import(/* webpackChunkName: "systemInfo" */ "@/views/SystemInfo.vue"),
    meta: {
      title: 'System Info | Development Only'
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      title: 'About Admin App | Documentation'
    }
  },
];


// Use ./ as the routing base if we're in dev mode, or if the env variables
// say this app has a subdomain of its own.  Otherwise assume it's running
// in the ./admin/ directory.
const useSubdirRouting = (process.env.VUE_APP_WEBCONFIG_TYPE !== 'subdomain') &&
  (process.env.NODE_ENV !== 'development');

const router = createRouter({
  history: createWebHistory(useSubdirRouting ? './admin/' : './'),
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that was defined above.
  let title = to.meta.title;
  // If the route has a title, use it as the page title. If not, we'll just title it Admin App.
  document.title = title ? title : "Admin App";
  // Continue resolving the route
  next();
});

export default router;
