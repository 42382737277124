import { createStore } from "vuex";

export default createStore({
  state: {
    adminState: {},
    casinoList: [],
    casinosIncludingDeleted: [],
    casinoGames: [],
    selectedUser: {},
    casinoPotFamilyNames: [],
    gameConfigOptionsList: [],
    currentGameTableData: [],
    progressivesSimpleList: [],
    casinoBasicDetails: {},
    errorList: []
  },
  getters: {
    adminState(state) {
      return state.adminState;
    },
    casinoList(state) {
      return state.casinoList;
    },
    casinosIncludingDeleted(state) {
      return state.casinosIncludingDeleted;
    },
    casinoGames(state) {
      return state.casinoGames;
    },
    selectedUser(state) {
      return state.selectedUser;
    },
    casinoPotFamilyNames(state) {
      return state.casinoPotFamilyNames;
    },
    gameConfigOptionsList(state) {
      return state.gameConfigOptionsList;
    },
    currentGameTableData(state) {
      return state.currentGameTableData;
    },
    progressivesSimpleList(state) {
      return state.progressivesSimpleList;
    },
    casinoBasicDetails(state) {
      return state.casinoBasicDetails;
    },
    errorList(state) {
      return state.errorList;
    }
  },
  mutations: {
    updateAdminState(state, payload) {
      state.adminState = payload;
    },
    updateCasinoList(state, payload) {
      state.casinoList = payload;
    },
    updateCasinosIncludingDeleted(state, payload) {
      state.casinosIncludingDeleted = payload;
    },
    updateCasinoGames(state, payload) {
      state.casinoGames = payload;
    },
    updateSelectedUser(state, payload) {
      state.selectedUser = payload;
    },
    updatePotFamilyNames(state, payload) {
      state.casinoPotFamilyNames = payload;
    },
    updateGameConfigOptionsList(state, payload) {
      state.gameConfigOptionsList = payload;
    },
    updateCurrentGameTableData(state, payload) {
      state.currentGameTableData = payload;
    },
    updateProgressivesSimpleList(state, payload) {
      state.progressivesSimpleList = payload;
    },
    updateCasinoBasicDetails(state, payload) {
      state.casinoBasicDetails = payload;
    },
    updateErrorList(state, payload) {
      state.errorList = payload;
    }
  },
  actions: {
    setAdminState(state, payload) {
      this.commit('updateAdminState', payload);
    },
    setCasinoList(state, payload) {
      this.commit('updateCasinoList', payload);
    },
    setCasinosIncludingDeleted(state, payload) {
      this.commit('updateCasinosIncludingDeleted', payload);
    },
    setCasinoGames(state, payload) {
      this.commit('updateCasinoGames', payload);
    },
    setSelectedUser(state, payload) {
      this.commit('updateSelectedUser', payload);
    },
    setPotFamilyNames(state, payload) {
      this.commit('updatePotFamilyNames', payload);
    },
    setGameConfigOptionsList(state, payload) {
      this.commit('updateGameConfigOptionsList', payload);
    },
    setCurrentGameTableData(state, payload) {
      this.commit('updateCurrentGameTableData', payload);
    },
    setProgressivesSimpleList(state, payload) {
      this.commit('updateProgressivesSimpleList', payload);
    },
    setCasinoBasicDetails(state, payload) {
      this.commit('updateCasinoBasicDetails', payload);
    },
    setErrorList(state, payload) {
      this.commit('updateErrorList', payload);
    }
  },
  modules: {},
});
