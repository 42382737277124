<template>
	<div class="home gradient-background">
		<h1 class="outline-white">Home Page</h1>
		<div v-if="adminState?.isLoggedOn">
			<h1 class="outline-white">Logged in as {{ adminState?.displayName }}</h1>
			<h2 class="outline-white">
				Phone:
				{{
					systemSettings?.allowPhoneExtensionDigits && phoneNumberParsed?.possibility !== "is-possible"
						? adminState?.username
						: phoneNumberParsed?.number?.national
				}}
			</h2>
			<!-- <select title="Select Casino" v-if="casinoListForUser?.length > 0" v-model="casinoId" id="casinoId">
				<option v-for="casino in casinoListForUser" v-bind:key="casino" :value="casino.id">
					{{ casino.name }}
				</option>
			</select>
			<DropdownMenu v-if="casinoListForUser?.length > 0 && !isMobile" :casinoListForUser="casinoListForUser" /> -->
			<div class="permissions-lists-container" v-if="casinoListForUser?.length > 0 && adminState?.casinoId">
				<table class="permissions">
					<tr class="header-row">
						<th colspan="2">{{ adminState?.displayName }} Permissions</th>
					</tr>
					<tr>
						<th>Cashier</th>
						<td :class="adminState?.isCashier.toString()">{{ adminState?.casinoId ? adminState?.isCashier : "N/A" }}</td>
					</tr>
					<tr>
						<th>Reporter</th>
						<td :class="adminState?.isReporter.toString()">{{ adminState?.casinoId ? adminState?.isReporter : "N/A" }}</td>
					</tr>
					<tr>
						<th>SiteAdmin</th>
						<td :class="adminState?.isSiteAdmin.toString()">{{ adminState?.casinoId ? adminState?.isSiteAdmin : "N/A" }}</td>
					</tr>
					<tr>
						<th>Manager</th>
						<td :class="adminState?.isManager.toString()">{{ adminState?.isManager }}</td>
					</tr>
					<tr>
						<th>Sysadmin</th>
						<td :class="adminState?.isSysadmin.toString()">{{ adminState?.isSysadmin }}</td>
					</tr>
					<tr>
						<th>HelpDesk</th>
						<td :class="adminState?.isHelpDesk.toString()">{{ adminState?.casinoId ? adminState?.isHelpDesk : "N/A" }}</td>
					</tr>
				</table>
				<div class="limitations-container" v-if="casinoListForUser?.length > 0 && adminState?.casinoId">
					<p v-if="adminState?.permissionsSuspended">
						Permissions for this account have been temporarily suspended. Please contact your administrator to have them restored.
					</p>
					<p
						v-if="
							devMode &&
							adminState?.isLoggedOn &&
							!(
								adminState?.isSysadmin ||
								adminState?.isCashier ||
								adminState?.isHelpDesk ||
								adminState?.isSiteAdmin ||
								adminState?.isManager
							)
						"
					>
						Your permissions do not allow access to User Manager.
					</p>
					<p
						v-if="
							!(adminState?.isSiteAdmin || adminState?.isSysadmin || adminState?.isManager) &&
							adminState?.isLoggedOn &&
							adminState?.casinoId &&
							devMode
						"
					>
						Your permissions do not allow access to Casino Administration for {{ adminState?.casinoName }}.
					</p>
				</div>
			</div>
			<div class="disabled-lists-container" v-if="disabledCasinos?.length > 0 && adminState?.casinoId">
				<table class="disabled">
					<tr class="header-row">
						<th colspan="2">Disabled Casinos</th>
					</tr>
					<tr class="header-row">
						<th>Casino ID</th>
						<th>Casino Name</th>
					</tr>
					<tr v-for="(item, index) in disabledCasinos" :key="index" :value="item.id">
						<td>{{ item.id }}</td>
						<td>{{ item.name }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";

// Tools from awesome-phonenumber. For now were just using parsePhoneNumber
// https://www.npmjs.com/package/awesome-phonenumber
import {
	parsePhoneNumber,
	// getNumberFrom,
	// getExample,
	// getCountryCodeForRegionCode,
	// getRegionCodeForCountryCode,
	// getSupportedCallingCodes,
	// getSupportedRegionCodes,
	// getAsYouType,
} from "awesome-phonenumber";

export default {
	name: "Home",
	props: {
		adminState: Object,
		casinoList: Array,
		disabledCasinos: Array,
		isMobile: Boolean,
		systemSettings: Object,
	},
	data() {
		return {
			casinoListForUser: [],
			// casinoId: null,
			phoneNumberParsed: "",
			userPermissions: {
				userId: "",
				globalPermissions: "",
				sitePermissions: {},
			},
			sitePermissions: {},
			isCashier: false,
			isReporter: false,
			isSiteAdmin: false,
			isSysadmin: false,
			isHelpDesk: false,
			isManager: false,
		};
	},
	watch: {
		adminState: {
			handler() {
				if (this.adminState.isLoggedOn) {
					this.listCasinosForThisUser();
					this.formatPhoneInternational();
				}
			},
			deep: true,
		},
	},
	created() {
		if (this.adminState?.isLoggedOn) {
			this.formatPhoneInternational();
		}
	},
	mounted() {
		this.listCasinosForThisUser();
	},
	methods: {
		formatPhoneInternational() {
			let phone = `+${this.adminState?.phoneCountryCode}${this.adminState?.username}`;
			this.phoneNumberParsed = parsePhoneNumber(phone);
		},
		listCasinosForThisUser() {
			let permissionsList = this.adminState?.userPermissions?.sitePermissions;
			let list = [];
			for (const key in permissionsList) {
				if (
					permissionsList[key].includes("Cashier") ||
					permissionsList[key].includes("SiteAdmin") ||
					permissionsList[key].includes("Manager")
				) {
					let casino = this.casinoList.filter((casino) => casino.id == key)[0];
					list.push(casino);
				}
			}
			list = list.sort((a, b) => a.name.localeCompare(b.name));
			this.casinoListForUser = this.adminState.isHelpDesk ? this.casinoList : list;
		},
	},
};
</script>

<style scoped>
.home {
	color: #000;
	height: calc(100vh - 150px);
	width: 80%;
	margin-left: 20%;
	margin-top: 0;
}

.permissions,
.disabled {
	position: relative;
	width: auto;
	margin: auto auto 15px;
	border-collapse: collapse;
	text-align: center;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.permissions *,
.disabled * {
	padding: 5px 10px;
	width: 50%;
	border: 1px #000 solid;
}

.permissions-lists-container {
	/* height: calc(100vh - 23em); */
	overflow: hidden auto;
}

.permissions tr {
	transition: background-color 0.3s;
}

tr.header-row {
	border-bottom: 1px solid #000;
}

.permissions tr.data-row:hover,
.permissions tr:nth-child(2n):not(.open):hover {
	background-color: #fff;
	color: #000;
}

.permissions tr:nth-child(2n):not(.open) {
	background-color: #bbb;
}

.true {
	background-color: #080;
	color: #fff;
}

.false {
	background-color: #646464;
	color: #969696;
}

.limitations-container p {
	width: 60%;
	padding: 15px 30px;
	margin: auto;
	background-color: rgb(67 66 80 / 50%);
	backdrop-filter: blur(3px);
	color: #ff0;
	text-align: center;
	font-weight: bold;
}

.limitations-container p:first-child {
	margin-top: 15px;
}

.limitations-container p + p {
	padding-top: 0 !important;
}
</style>
